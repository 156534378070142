/* eslint-disable no-constructor-return */
import { io } from 'socket.io-client';
import keys from '../../services/keys';
import { getDeviceId } from '../users/checkUserInfo';

class Socket {
  private socket: any = io(`${keys.SOCKET_URL}/message`, {
    autoConnect: false,
  });

  connectWithAuthToken(token: string) {
    this.socket.io.opts.extraHeaders = {}
    this.socket.io.opts.extraHeaders.Authorization = `Bearer ${token}`
    this.socket.io.opts.extraHeaders.Device = getDeviceId()

    if (!this.socket.connected) {
      this.socket.connect();
    }
  }

  get socketInfo() {
    return this.socket;
  }

  disconnect() {
    this.socket?.emit('leave');
    this.socket?.disconnect();
  }

  emit(event: string, data: any) {
    this.socket?.emit(event, data);
  }

  on(event: string, callBack: (data: any) => void) {
    if (this.socket) {
      this.socket?.off(event, callBack)
      this.socket?.on(event, callBack);
    }
  }

  off(event: string, callback: (data: any) => void) {
    this.socket?.off(event, callback)
  }
}

export default new Socket();