import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import _ from 'lodash';
import { loadingStatus } from '../../interfaces/MainInterfaces';
import {
  chatMessage,
  giftItem,
  giftToGiveItem,
  roomsList,
  giftCategory,
  rateType,
} from '../../interfaces/GameTableInterfaces';
import AuthAPI from '../../services/AuthAPI';
import API from '../../services/API';

import { RootState } from '../store';

interface initState {
  game: {
    background: string,
    allGiftsList: giftItem[],
    giftsList: {
      list: giftItem[],
      newGifts: giftItem[]
    },
    giftsToGive: giftToGiveItem[],
    roomInfo: {
      chat: chatMessage[]
    },
    agoraUser: any,
    usersBlocksRefs: Map<string, number>,
    rooms: {
      list: {
        roomList: roomsList[],
        count: number
      } | null,
      status: 'loading' | 'loaded'
    },
    room: roomsList | null,
    chatMessages: any[],
    musics: {
      href: string,
      startedAt: number
    }[],
    giftCategories: {
      status: loadingStatus,
      list: giftCategory[]
    },
    usersPoints: any,
    rateTypes: rateType[],
    openLevelUpModal: boolean
  }
}

const initialState: initState = {
  game: {
    background: '/assets/images/pngs/game-background1.png',
    giftsList: {
      list: [],
      newGifts: [],
    },
    allGiftsList: [],
    giftsToGive: [],
    roomInfo: {
      chat: [],
    },
    agoraUser: null,
    usersBlocksRefs: new Map(),
    rooms: {
      list: null,
      status: 'loading',
    },
    room: null,
    chatMessages: [],
    musics: [],
    giftCategories: {
      status: 'loading',
      list: [],
    },
    usersPoints: [],
    rateTypes: [],
    openLevelUpModal: false,
  },
};

export const getGifts = createAsyncThunk(
  'game/getGifts',
  async (id: number) => {
    const response = await API.get(`/gift/all?ispremium=null&skip=0&take=1000&category=${id || 11}&visible=null`)

    return response.data
  },
)

export const getAllGifts = createAsyncThunk(
  'game/getAllGifts',
  async () => {
    const response = await API.get('/gift/all?ispremium=null&skip=0&take=1000&category=null&visible=null')

    return response.data
  },
)

export const getRooms = createAsyncThunk(
  'game/getRooms',
  async (search: string) => {
    const response = await AuthAPI.get(`room/for-me${search}`)

    return response.data
  },
)

export const createRoom = createAsyncThunk(
  'game/createRoom',
  async (data: any) => {
    const response = await AuthAPI.post('/room/create/empty-room', data)

    return response.data
  },
)

export const getGiftCategories = createAsyncThunk(
  'game/getGiftCategories',
  async () => {
    const response = await AuthAPI.get('/gift-category');

    return response.data
  },
)

export const getRateTypes = createAsyncThunk(
  'game/getRateTypes',
  async () => {
    const response = await AuthAPI.get('/RateType/all?skip=0&take=10&category=null&ispremium=null&visible=true')

    return response.data
  },
)

export const sendRate = createAsyncThunk(
  'game/sendRate',
  async (data: any) => {
    const response = await AuthAPI.post('/RateType/photo/rate', data)

    return response.data
  },
)

export const getRoom = createAsyncThunk(
  'game/getRoom',
  async (id: number) => {
    const response = await AuthAPI.get(`/room/${id}`)

    return response.data
  },
)

export const createMoneyTransaction = createAsyncThunk(
  'game/getAudioPlayerVideos',
  async (data: any) => {
    const response = await AuthAPI.post('/user/money-transaction', data)

    return response.data
  },
)

export const getRandomRoom = createAsyncThunk(
  '/game/getRandomRoom',
  async ({ cb }: { cb: any }) => {
    const response = await AuthAPI.get('/room/random')

    cb(response.data.id)
  },
)

export const sendVoiceMessageOnChat = createAsyncThunk(
  '/game/sendVoiceMessageOnChat',
  async ({ voice, cb }: { voice: any, cb: any }) => {
    const response = await AuthAPI.post('file/upload/voice', voice, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    cb(response.data.path)
  },
)

export const sendVoiceMessageOnDirectChat = createAsyncThunk(
  '/game/sendVoiceMessageOnChat',
  async ({ voice, cb }: { voice: any, cb: any }) => {
    const response = await AuthAPI.post('file/upload/direct-voice', voice, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    cb(response.data.path)
  },
)

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setGifts: (state, action: PayloadAction<{
      delete: boolean,
      list: any[]
    }>) => {
      if (action.payload.delete) {
        const newList = action.payload.list.filter(item => dayjs(item.date).diff(dayjs()) > 0)
        state.game.giftsList.list = newList;
        state.game.giftsList.newGifts = action.payload.list
      } else {
        const newList = [...action.payload.list, ...state.game.giftsList.list].filter(item => dayjs(item.date).diff(dayjs()) > 0)

        state.game.giftsList.list = _.uniqBy(newList, obj => {
          const gift = obj

          if (gift.gift.category.id === 12) {
            return `${gift.position.x}-${gift.position.y}-${obj.userId}`
          } else {
            return `${gift.position}-${obj.userId}`
          }
        })
        state.game.giftsList.newGifts = action.payload.list
      }
    },

    filterGifts: (state, action: PayloadAction<{
      users: number[]
    }>) => {
      state.game.giftsList.list = state.game.giftsList.list.filter(item => !action.payload.users.includes(item.userId));
      state.game.giftsList.newGifts = []
    },

    setRoomInfo: (state, action) => {
      state.game.roomInfo = {
        ...action.payload,
      };
    },

    setAgoraUser: (state, action) => {
      state.game.agoraUser = action.payload
    },

    setMusics: (state, action) => {
      state.game.musics = action.payload;
    },

    setUsersBlockRefs: (state, action) => {
      state.game.usersBlocksRefs.set(action.payload.id, action.payload.ref)
    },

    setComments: (state, action) => {
      state.game.chatMessages = action.payload
    },

    setSingleComment: (state, action) => {
      state.game.chatMessages.push(action.payload)
    },

    resetUsersPoints: (state) => {
      state.game.usersPoints = []
    },

    setUsersPoints: (state, action) => {
      state.game.usersPoints.push(action.payload)
    },

    changeLevelUpModal: (state, action: PayloadAction<{
      open: boolean
    }>) => {
      state.game.openLevelUpModal = action.payload.open
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGifts.pending, (state, action) => {
      state.game.giftsToGive = [];
    })
    builder.addCase(getGifts.fulfilled, (state, action) => {
      state.game.giftsToGive = action.payload.giftList;
    })

    builder.addCase(getAllGifts.fulfilled, (state, action) => {
      state.game.allGiftsList = action.payload.giftList;
    })

    builder.addCase(getRooms.pending, (state, action) => {
      state.game.rooms.status = 'loading'
    })
    builder.addCase(getRooms.fulfilled, (state, action) => {
      state.game.rooms.list = action.payload
      state.game.rooms.status = 'loaded'
    })

    builder.addCase(getGiftCategories.pending, (state, action) => {
      state.game.giftCategories.status = 'loading'
    })
    builder.addCase(getGiftCategories.fulfilled, (state, action) => {
      state.game.giftCategories.status = 'loaded'
      state.game.giftCategories.list = action.payload
    })

    builder.addCase(getRateTypes.fulfilled, (state, action) => {
      state.game.rateTypes = action.payload.rateTypeList
    })

    builder.addCase(getRoom.fulfilled, (state, action) => {
      state.game.room = action.payload
    })
  },
});

export const getGiftsCategoriesInfo = (state: RootState) => state.game.game.giftCategories;
export const getLevelUpModalInfo = (state: RootState) => state.game.game.openLevelUpModal
export const getUsersBlockRefs = (state: RootState) => state.game.game.usersBlocksRefs;
export const getRoomChatData = (state: RootState) => state.game.game.roomInfo?.chat;
export const getChatMessages = (state: RootState) => state.game.game.chatMessages;
export const getGameBackground = (state: RootState) => state.game.game.background;
export const getAllGiftsList = (state: RootState) => state.game.game.allGiftsList;
export const getUsersPoints = (state: RootState) => state.game.game.usersPoints;
export const getRateTypesInfo = (state: RootState) => state.game.game.rateTypes;
export const getGiftsToGive = (state: RootState) => state.game.game.giftsToGive;
export const getGameGifts = (state: RootState) => state.game.game.giftsList;
export const getAgoraUser = (state: RootState) => state.game.game.agoraUser;
export const getRoomsData = (state: RootState) => state.game.game.rooms;
export const getRoomInfo = (state: RootState) => state.game.game.room

export const {
  setGifts,
  setAgoraUser,
  setUsersBlockRefs,
  setComments,
  setSingleComment,
  setMusics,
  resetUsersPoints,
  setUsersPoints,
  changeLevelUpModal,
  filterGifts,
} = gameSlice.actions;

export default gameSlice.reducer;