import { v4 as uuid } from 'uuid'
import { UserRole } from '../../enums';
import { UserActions } from '../../interfaces/UserInterfaces';

export const getDeviceId = () => {
  let deviceId = localStorage.getItem('DEVICE_ID_KEY') || ''
  if (!deviceId) {
    deviceId = uuid()
    localStorage.setItem('DEVICE_ID_KEY', deviceId)
  }
  return deviceId
}

export default function checkIsUserPremium(user: any) {
  return user?.ispremium
}

export function checkUserRole(action: UserActions, role: any) {
  const rolesForUserKick = [UserRole.ADMIN, UserRole.SUPER_ADMIN, UserRole.MODERATOR, UserRole.BOSS]

  switch (action) {
    case 'kick-user':
      if (rolesForUserKick.includes(rolesForUserKick[role])) {
        return true
      }
      break;

    default:
      break;
  }
}