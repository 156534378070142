import {
  useCallback, useEffect, useMemo, useState,
} from 'react'
import dayjs from 'dayjs'
import { getUserInfo, updateUserInterfaceLanguage } from '../../../../app/slices/usersSlice'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import getCurrentEnv from '../../../../utils/variables'
import getLocale from '../../../../utils/languages'
import i18n from '../../../../i18n'
import './style.scss'

const languages = [
  'EN',
  'ES',
  'CN',
  'FR',
  'KR',
  'JP',
  'RU',
]

export default function Language() {
  const dispatch = useAppDispatch()

  const userInfo = useAppSelector(getUserInfo)

  const [currentLang, setCurrentLang] = useState(i18n.language)
  const [openList, setOpenList] = useState(false)

  const langs = useMemo(() => languages.filter(item => item !== currentLang), [currentLang])

  const handleChangeLanguage = useCallback((item: string) => {
    const user: any = userInfo?.user

    if (user) {
      dispatch(updateUserInterfaceLanguage({
        interfaceLang: item,
        id: +user.id,
      }))
    }

    dayjs.locale(getLocale(item))

    setCurrentLang(item)
    setOpenList(false)
    i18n.changeLanguage(item.toLocaleLowerCase())
  }, [userInfo])

  useEffect(() => {
    dayjs.locale(getLocale(userInfo?.user?.interfaceLang))
    setCurrentLang(i18n.language)
  }, [userInfo])

  if (getCurrentEnv() === 'vk') {
    return (<div />)
  }

  return (
    <div
      className='navbar-language'
      onMouseEnter={() => setOpenList(true)}
      onMouseLeave={() => setOpenList(false)}
    >
      <div>
        <img src={`./assets/images/flags/${currentLang.toLowerCase()}.png`} alt='current-lang' />
      </div>
      <div className={`list ${openList && 'active'}`}>
        {langs.map((item, index) => (
          <img
            key={index}
            onClick={() => {
              handleChangeLanguage(item)
            }}
            src={`./assets/images/flags/${item.toLowerCase()}.png`}
            alt='current-lang'
          />
        ))}
      </div>
    </div>
  )
}