/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, CircularProgress,
} from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import AuthFormHead from '../../../../components/Authentication/Layouts/AuthFormHead';
import { loadingStatus } from '../../../../interfaces/MainInterfaces';
import { authRegistration } from '../../../../app/slices/usersSlice';
import { useAppDispatch } from '../../../../app/hooks';
import { changePassword } from '../../../../app/slices/authSlice';

const styles = {
  textField: {
    backgroundColor: '#fff',
    width: '100%',
    outline: 'none',
    borderRadius: '5px',
  },
  input: {
    outline: 'none',
  },
}

interface props {
  handleChangePage: (page: number) => void
}

export default function CreatePassword({
  handleChangePage,
}: props) {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const [confirmPasswordError, setConfirmPasswordError] = useState(false)
  const [loading, setLoading] = useState<loadingStatus>('loaded')
  const [showPassword, setShowPassword] = useState(false);

  const {
    handleSubmit, control, formState: { errors },
  } = useForm({
    defaultValues: {
      password: '',
      confirm_password: '',
    },
  });

  const onSubmit = (data: any) => {
    if (data.password !== data.confirm_password) {
      setConfirmPasswordError(true)
    } else {
      setLoading('loading')

      const email = sessionStorage.getItem('reset_pass_email') || ''

      setConfirmPasswordError(false)

      dispatch(changePassword({
        email,
        password: data.password,
      })).then(response => {
        if (response?.payload?.status < 300) {
          handleChangePage(2)
        } else {
          setLoading('loaded')
        }
      })
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <div className='auth-form'>
      <AuthFormHead
        title='Reset your password'
        text='create a new password'
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputLabel
          className='auth-form-label'
          htmlFor='component-simple'
        >
          {t('registration.enterPassword')}
        </InputLabel>
        <FormControl variant='outlined' className='auth-form-body-input'>
          <Controller
            control={control}
            name='password'
            rules={{
              required: {
                value: true,
                message: t('errors.required'),
              },
              min: {
                value: 6,
                message: `${t('errors.minLength')} 6`,
              },
            }}
            render={({
              field: {
                onChange, value,
              },
            }) => (
              <OutlinedInput
                id='registration-password'
                type={showPassword ? 'text' : 'password'}
                onChange={onChange}
                value={value}
                style={styles.textField}
                inputProps={styles.input}
                error={!!errors.password}
                endAdornment={(
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )}
              />
            )}
          />
          {!!errors.password && (
            <FormHelperText error id='accountId-error'>
              {errors.password.message}
            </FormHelperText>
          )}
        </FormControl>
        <InputLabel
          className='auth-form-label'
          htmlFor='component-simple'
        >
          {t('registration.confirmPassword')}
        </InputLabel>
        <FormControl variant='outlined' className='auth-form-body-input'>
          <Controller
            control={control}
            name='confirm_password'
            rules={{
              required: {
                value: true,
                message: t('errors.required'),
              },
            }}
            render={({
              field: {
                onChange, value,
              },
            }) => (
              <OutlinedInput
                id='registration-confirm-password'
                type={showPassword ? 'text' : 'password'}
                onChange={onChange}
                value={value}
                style={styles.textField}
                inputProps={styles.input}
              />
            )}
          />
          {confirmPasswordError && (
            <FormHelperText error id='accountId-error'>
              {t('registration.passwordsNotSame')}
            </FormHelperText>
          )}
        </FormControl>
        <Button
          className='registrationButton'
          variant='contained'
          type='submit'
        >
          {loading === 'loading' ? <CircularProgress /> : 'Next'}
        </Button>
      </form>
    </div>
  )
}