import { useState, useEffect } from 'react'
import loadingCacheCleanBtnIcon from '../../assets/images/loading-cache-clean-btn.jpg'
import loadingCacheCleanIcon from '../../assets/images/loading-cache-clean.jpg'
import LoadingSvg from '../../utils/svgs/loadingSvg';
import getCurrentEnv from '../../utils/variables';
import './style.scss'

export default function Loading() {
  const [showClearText, setShowClearText] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShowClearText(true)
    }, 5000);
  }, [])

  return (
    <div className='main-loading'>
      <LoadingSvg />
      {showClearText && <>
        <span>Если игра не загружается более 10 секунд , попробуйте очистить кэш и перезапустить приложение</span>
        {
          getCurrentEnv() === 'vk' && <>
            <img src={loadingCacheCleanIcon} alt='loadingCacheCleanIcon' />
            <img src={loadingCacheCleanBtnIcon} alt='loadingCacheCleanBtnIcon' />
          </>
        }
      </>}
    </div>
  )
}