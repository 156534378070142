import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { loadingStatus } from '../../interfaces/MainInterfaces'
import audioItem, { playlistItem } from '../../interfaces/AudioInterfaces'
import AudioAPI from '../../services/AudioAPI'
import { RootState } from '../store'

interface initState {
  musicList: {
    data: audioItem[],
    count: number
  } | null,
  playList: {
    playlist: playlistItem[],
    count: number
  } | null,
  status: loadingStatus,
  audioEditStatus: loadingStatus
}

const initialState: initState = {
  musicList: null,
  playList: null,
  status: 'loaded',
  audioEditStatus: 'loaded',
}

export const getAudioList = createAsyncThunk(
  'audio/getAudioList',
  async (search: any) => {
    const response = await AudioAPI.get(`/music/get?${search}`)

    return response.data;
  },
)

export const getAudioListById = createAsyncThunk(
  'audio/getAudioListById',
  async (search: any) => {
    const response = await AudioAPI.get(`/music/playlist/my?${search}`)

    return response.data;
  },
)

export const uploadAudio = createAsyncThunk(
  'audio/uploadAudio',
  async (data: any) => {
    const response = await AudioAPI.post('/music/add', data);

    return response.data;
  },
)

export const editAudio = createAsyncThunk(
  'audio/editAudio',
  async (data: {
    id: number,
    title: string,
    cb: any
  }) => {
    const { id, title, cb } = data

    const response = await AudioAPI.put('/music/update', {
      id,
      title,
    });

    cb()

    return response.data;
  },
)

export const addToPlaylist = createAsyncThunk(
  'audio/addToPlaylist',
  async (data: any) => {
    const response = await AudioAPI.post('/music/playlist/my', {
      musicId: data.id,
    });

    return response.data;
  },
)

export const deleteFromPlaylist = createAsyncThunk(
  'audio/addToPlaylist',
  async (data: any) => {
    const response = await AudioAPI.delete(`/music/playlist/my/${data.id}`);

    data.cb()

    return response.data;
  },
)


const audio = createSlice({
  name: 'audio',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAudioListById.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(getAudioListById.fulfilled, (state, action) => {
      state.status = 'loaded'

      state.playList = {
        ...action.payload,
      }

      state.musicList = {
        data: state.musicList?.data || [],
        count: action.payload.count,
      }
    })
    builder.addCase(getAudioList.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(getAudioList.fulfilled, (state, action) => {
      state.musicList = {
        ...action.payload,
      }
      state.status = 'loaded'
    })

    builder.addCase(editAudio.pending, (state) => {
      state.audioEditStatus = 'loading'
    })
    builder.addCase(editAudio.fulfilled, (state) => {
      state.audioEditStatus = 'loaded'
    })
    builder.addCase(editAudio.rejected, (state) => {
      state.audioEditStatus = 'loaded'
    })
  },
})

export const getAudioEditInfo = (state: RootState) => state.audio.audioEditStatus
export const getAudioListStatusInfo = (state: RootState) => state.audio.status
export const getAudioListInfo = (state: RootState) => state.audio.musicList
export const getPlaylistInfo = (state: RootState) => state.audio.playList

export default audio.reducer