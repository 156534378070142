import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { moneyTypes, userInfo } from '../../interfaces/GameTableInterfaces';
import { GenderEnum } from '../../features/Authentication/utils/enums';
import { audioType } from '../../interfaces/MainInterfaces';
import { getCurrentDeviceEnv } from '../../utils/variables';
import AuthAPI from '../../services/AuthAPI';
import { currencyEnum } from '../../enums';
import { RootState } from '../store';

const audioMute = localStorage.getItem('audio_mute')

interface initState {
  roomBoss: null | userInfo,
  userRoom: any,
  profile: {
    open: boolean,
    profileUserId: null | number,
    history: number[],
    isOpen: number
  },
  money: {
    moneyToSpend: number,
    moneyType: moneyTypes,
    cb: any
  },
  shop: {
    open: boolean,
    activeSection: currencyEnum
  },
  agora: {
    inCall: boolean
  },
  gamesData: {
    linesColor: string
  },
  sendMoney: {
    status: 'sending' | 'sended'
  },
  fbData: {
    firstName: string,
    lastName: string,
    ISO: string,
    gender: GenderEnum | null,
    interestedIn: number | null,
    birthday: string | null,
    avatar: string,
    interfaceLang: string,
    id: number,
    update: boolean,
    socialId: number
  },
  chooseFavoriteGameSelected: boolean,
  audio: {
    url: string,
    type: audioType,
    render: number,
    mute: boolean
  },
  openLuckyModal: boolean
}

const initialState: initState = {
  roomBoss: null,
  userRoom: null,
  profile: {
    open: false,
    profileUserId: null,
    history: [],
    isOpen: Math.random(),
  },
  money: {
    moneyToSpend: 0,
    moneyType: 'gold',
    cb: null,
  },
  shop: {
    activeSection: currencyEnum.gold,
    open: false,
  },
  agora: {
    inCall: false,
  },
  gamesData: {
    linesColor: '#fff',
  },
  sendMoney: {
    status: 'sended',
  },
  fbData: {
    firstName: '',
    lastName: '',
    ISO: '',
    gender: null,
    interestedIn: 0,
    birthday: '',
    avatar: '',
    interfaceLang: '',
    id: 0,
    update: false,
    socialId: 0,
  },
  chooseFavoriteGameSelected: false,
  audio: {
    type: '',
    url: '',
    render: 2,
    mute: audioMute ? JSON.parse(audioMute).mute : false,
  },
  openLuckyModal: false,
};

export const sendMoney = createAsyncThunk(
  'game/sendMoney',
  async (data: any) => {
    const response = await AuthAPI.post('/user/send-money', data)

    return response.data
  },
)

export const sendFit = createAsyncThunk(
  'game/sendFit',
  async (data: any) => {
    const response = await AuthAPI.post('/user/send-fit', data)

    return response.data
  },
)

export const sendBoosterGift = createAsyncThunk(
  'game/sendBoosterGift',
  async (data: any) => {
    const response = await AuthAPI.post('/user/gift-booster', data)

    return response.data
  },
)

export const sendPremiumGift = createAsyncThunk(
  'game/sendBoosterGift',
  async (data: any) => {
    const response = await AuthAPI.post('/user/gift-premium', data)

    return response.data
  },
)

export const sendLuckyStatusGift = createAsyncThunk(
  'game/sendLuckyStatusGift',
  async (data: any) => {
    const response = await AuthAPI.post(`/user/gift/lucky-status/${data.userId}`, data)

    return response.data
  },
)

export const deleteConverstaion = createAsyncThunk(
  'game/deleteConverstaion',
  async (data: {
    id: number,
    cb: any
  }) => {
    const response = await AuthAPI.delete(`/message/delete-conversation?toId=${data.id}`)

    data.cb()

    return response.data
  },
)

const gameInfo = createSlice({
  name: 'game',
  initialState,
  reducers: {
    changeRoomBoss: (state, action) => {
      state.roomBoss = action.payload
    },

    changeUserRoom: (state, action) => {
      state.userRoom = action.payload
    },

    openProfileModal: (state, action: PayloadAction<{
      id: number
    }>) => {
      state.profile = {
        ...state.profile,
        profileUserId: action.payload.id,
        open: true,
        history: [
          ...state.profile.history.filter(item => item !== action.payload.id),
          action.payload.id,
        ],
        isOpen: Math.random(),
      }
    },
    closeProfileModal: (state) => {
      state.profile = {
        ...state.profile,
        open: false,
        history: [],
      }
    },
    changeProfileInfo: (state, action: PayloadAction<{
      open: boolean,
      profileUserId: number
    }>) => {
      state.profile = {
        ...state.profile,
        ...action.payload,
      }
    },
    changeProfileHistory: (state) => {
      if (state.profile.history.length > 1) {
        state.profile = {
          ...state.profile,
          profileUserId: state.profile.history[state.profile.history.length - 2],
          history: state.profile.history.slice(0, state.profile.history.length - 1),
        }
      } else {
        state.profile = {
          ...state.profile,
          open: false,
          history: [],
        }
      }
    },

    changeMoneyInfo: (state, action) => {
      state.money = action.payload
    },

    openShopModal: (state, action: PayloadAction<{
      open: boolean,
      activeSection: currencyEnum
    }>) => {
      if (getCurrentDeviceEnv() !== 'vk-ios') {
        state.shop = action.payload
      }
    },
    closeShopModal: (state) => {
      state.shop.open = false
    },
    changeShopData: (state, action) => {
      state.shop = {
        ...state.shop,
        ...action.payload,
      }
    },

    changeAgoraDate: (state, action) => {
      state.agora = {
        ...state.agora,
        ...action.payload,
      }
    },

    changeGameData: (state, action) => {
      state.gamesData = {
        ...state.gamesData,
        ...action.payload,
      }
    },

    changeFbData: (state, action: PayloadAction<{
      firstName: string,
      lastName: string,
      ISO: string,
      gender: GenderEnum | null,
      interestedIn: number | null,
      birthday: string | null,
      avatar: string,
      interfaceLang: string,
      id: number,
      update: boolean,
      socialId: number
    }>) => {
      state.fbData = action.payload
    },

    changeChooseFavoriteGameSelected: (state, action) => {
      state.chooseFavoriteGameSelected = action.payload
    },

    changeAudioType: (state, action: PayloadAction<{
      type: audioType
    }>) => {
      state.audio = {
        ...state.audio,
        type: action.payload.type,
        render: Math.random(),
      }
    },
    changeAudioInfo: (state, action: PayloadAction<{
      mute: boolean
    }>) => {
      state.audio = {
        ...state.audio,
        ...action.payload,
      }
    },
    changeLuckyModalOpen: (state) => {
      state.openLuckyModal = !state.openLuckyModal
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendMoney.pending, (state) => {
      state.sendMoney.status = 'sending'
    })
    builder.addCase(sendMoney.fulfilled, (state) => {
      state.sendMoney.status = 'sended'
    })
    builder.addCase(sendMoney.rejected, (state) => {
      state.sendMoney.status = 'sended'
    })
  },
});

export const {
  changeFbData,
  openShopModal,
  changeGameData,
  changeRoomBoss,
  changeUserRoom,
  closeShopModal,
  changeShopData,
  changeAgoraDate,
  changeMoneyInfo,
  openProfileModal,
  closeProfileModal,
  changeProfileInfo,
  changeProfileHistory,
  changeChooseFavoriteGameSelected,
  changeAudioType,
  changeAudioInfo,
  changeLuckyModalOpen,
} = gameInfo.actions

export const getChooseFavoriteGameSelected = (state: RootState) => state.gameInfo.chooseFavoriteGameSelected
export const getGameLinesColor = (state: RootState) => state.gameInfo.gamesData.linesColor
export const getLuckyModalOpen = (state: RootState) => state.gameInfo.openLuckyModal
export const getSendMoneyInfo = (state: RootState) => state.gameInfo.sendMoney
export const getProfileInfo = (state: RootState) => state.gameInfo.profile
export const roomBossInfo = (state: RootState) => state.gameInfo.roomBoss
export const getGameInfo = (state: RootState) => state.gameInfo.gamesData
export const userRoomInfo = (state: RootState) => state.gameInfo.userRoom
export const getFBDataInfo = (state: RootState) => state.gameInfo.fbData
export const getMoneyInfo = (state: RootState) => state.gameInfo.money
export const getAgoraInfo = (state: RootState) => state.gameInfo.agora
export const getAudioInfo = (state: RootState) => state.gameInfo.audio
export const getShopInfo = (state: RootState) => state.gameInfo.shop

export default gameInfo.reducer;