import { createContext, useContext } from 'react';

type GlobalContext = {
  theme: string;
  setTheme: (theme: string) => void;
};

const MyGlobalContext = createContext<GlobalContext>({
  theme: 'green', // Set a default value
  setTheme: () => { },
});

const useGlobalContext = (): GlobalContext => useContext(MyGlobalContext);

export { MyGlobalContext, useGlobalContext };