import {
  PayloadAction, createAsyncThunk, createSlice, isRejectedWithValue,
} from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import AuthAPI from '../../services/AuthAPI'
import { commentsItem, multimediaType, subscriberItem } from '../../interfaces/GameTableInterfaces'
import { loadingStatus } from '../../interfaces/MainInterfaces'
import { blockedUser, userGuests } from '../../interfaces/ProfileInterfaces'
import imageUpload from '../../utils/upload/cloudinaryUpload'
import { RootState } from '../store'


interface initState {
  guests: {
    status: loadingStatus,
    list: {
      count: null | number,
      guestList: userGuests[]
    }
  },
  subscribers: {
    status: loadingStatus,
    list: {
      count: number,
      subscribersList: subscriberItem[]
    }
  },
  subscribes: {
    status: loadingStatus,
    list: {
      count: number,
      subscribeList: subscriberItem[]
    }
  },
  commentsList: {
    status: loadingStatus,
    list: commentsItem[]
  },
  multimediaList: {
    status: loadingStatus,
    multimediaList: multimediaType[],
    count: number
  },
  photoRates: {
    rates: [],
    isRated: false,
  },
  blockedUsersList: blockedUser[]
}

const initialState: initState = {
  guests: {
    status: 'loading',
    list: {
      count: null,
      guestList: [],
    },
  },
  subscribers: {
    status: 'loading',
    list: {
      count: 0,
      subscribersList: [],
    },
  },
  subscribes: {
    status: 'loading',
    list: {
      count: 0,
      subscribeList: [],
    },
  },
  commentsList: {
    list: [],
    status: 'loading',
  },
  multimediaList: {
    status: 'loading',
    multimediaList: [],
    count: 0,
  },
  photoRates: {
    rates: [],
    isRated: false,
  },
  blockedUsersList: [],
}


export const getUserGuests = createAsyncThunk(
  'user/getUserGuests',
  async () => {
    const response = await AuthAPI.get('/user/guest/all?skip=0&take=1000')

    return response.data;
  },
)

export const setGuest = createAsyncThunk(
  '/users/setGuest',
  async (id: number) => {
    const response = await AuthAPI.post('/user/set-guest', {
      hostId: id,
    })
  },
)

export const subscribeUser = createAsyncThunk(
  'user/subscribeUser',
  async (data: {
    id: number,
    cb: () => void
  }) => {
    const response = await AuthAPI.post(`/user/subscribe/${data.id}`)

    data.cb()

    return response.data
  },
)

export const unsubscribeUser = createAsyncThunk(
  'user/unsubscribeUser',
  async (data: {
    id: number,
    cb: () => void
  }) => {
    const response = await AuthAPI.delete(`/user/subscribe/${data.id}`)

    data.cb()

    return response.data
  },
)

export const handleGetSubscribers = createAsyncThunk(
  'user/getSubscribedUsers',
  async (id: number) => {
    const response = await AuthAPI.get(`/user/subscribers/${id}?skip=0&take=1000`)

    return response.data
  },
)

export const handleGetSubscribes = createAsyncThunk(
  'user/getUserSubscribes',
  async (id: number) => {
    const response = await AuthAPI.get(`user/subscribes/${id}?skip=0&take=1000`)

    return response.data
  },
)

export const uploadMedia = createAsyncThunk(
  '/user/uploadMedia',
  async (data: any) => {
    let image: any;
    if (data.file) {
      image = await imageUpload(data.file)
    }

    await AuthAPI.post('/user/multimedia', {
      url: image.data.public_id,
      type: data.type,
    })

    data.cb()
  },
)

export const getUserComments = createAsyncThunk(
  '/user/comments',
  async (id: number) => {
    const response = await AuthAPI.get(`/user/comment?reseiverId=${id}&skip=0&take=1000`)

    return response.data
  },
)

export const deleteUserComment = createAsyncThunk(
  '/user/deleteComment',
  async ({
    cb,
    id,
  }: {
    cb: any,
    id: number
  }) => {
    const response = await AuthAPI.delete(`/user/comment/${id}`)

    cb()

    return response.data
  },
)

export const getUserMultimedia = createAsyncThunk(
  '/user/multimedia',
  async ({
    id,
    search,
  }: {
    id: number,
    search: string
  }) => {
    const response = await AuthAPI.get(`/user/multimedia/${id}?${search}`)

    return response.data
  },
)

export const deleteUserMultimedia = createAsyncThunk(
  '/user/deleteMultimedia',
  async ({
    id,
    cb,
  }: {
    id: number,
    cb: any
  }) => {
    await AuthAPI.delete(`/user/multimedia/${id}`)

    cb()
  },
)

export const sendComment = createAsyncThunk(
  '/user/sendComment',
  async (data: {
    receiverId: number,
    comment: string
  }) => {
    const response = await AuthAPI.post('/user/comment', {
      resiverId: data.receiverId,
      description: data.comment,
    })

    return response.data
  },
)

export const getPhotoRates = createAsyncThunk(
  '/users/getPhotoRates',
  async (id: number) => {
    const response = await AuthAPI.get(`/RateType/multimedia/${id}`)

    return response.data
  },
)

export const blockUser = createAsyncThunk(
  '/users/blockUser',
  async (id: number) => {
    const response = await AuthAPI.post(`/user/block/${id}`)

    return response.data
  },
)

export const getBlockList = createAsyncThunk(
  '/users/getBlockList',
  async () => {
    const response = await AuthAPI.get('/user/my/block-list')

    return response.data
  },
)

export const deleteSubscribers = createAsyncThunk(
  '/users/deleteSubscribers',
  async (id: number) => {
    const response = await AuthAPI.delete(`/user/subscriber/${id}`)

    return response.data
  },
)

const profileInfo = createSlice({
  name: 'profileInfo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserGuests.pending, (state) => {
      state.guests.status = 'loading';
    });
    builder.addCase(getUserGuests.fulfilled, (state, action: PayloadAction<any>) => {
      state.guests.list = action.payload;
      state.guests.status = 'loaded';
    });

    builder.addCase(handleGetSubscribers.pending, (state) => {
      state.subscribers.status = 'loading'
    })
    builder.addCase(handleGetSubscribers.fulfilled, (state, action) => {
      state.subscribers.list = action.payload
      state.subscribers.status = 'loaded'
    })

    builder.addCase(handleGetSubscribes.pending, (state) => {
      state.subscribes.status = 'loading'
    })
    builder.addCase(handleGetSubscribes.fulfilled, (state, action) => {
      state.subscribes.list = action.payload
      state.subscribes.status = 'loaded'
    })

    builder.addCase(getUserComments.pending, (state) => {
      state.commentsList.status = 'loading'
    })
    builder.addCase(getUserComments.fulfilled, (state, action) => {
      state.commentsList.status = 'loaded'
      state.commentsList.list = action.payload.commentList
    })

    builder.addCase(deleteUserComment.pending, (state) => {
      state.commentsList.status = 'loading'
    })

    builder.addCase(sendComment.pending, (state) => {
      state.commentsList.status = 'loading'
    })
    builder.addCase(sendComment.fulfilled, (state, action) => {
      state.commentsList.status = 'loaded'
      state.commentsList.list.push(action.payload)
    })
    builder.addCase(sendComment.rejected, (state, action) => {
      state.commentsList.status = 'loaded'

      toast.error('User has blocked you')
    })

    builder.addCase(deleteUserMultimedia.pending, (state) => {
      state.multimediaList.status = 'loading'
    })

    builder.addCase(getUserMultimedia.pending, (state) => {
      state.multimediaList.status = 'loading'
    })
    builder.addCase(getUserMultimedia.fulfilled, (state, action) => {
      state.multimediaList.status = 'loaded'
      state.multimediaList = action.payload
    })

    builder.addCase(uploadMedia.pending, (state) => {
      state.multimediaList.status = 'loading'
    })

    builder.addCase(getPhotoRates.fulfilled, (state, action) => {
      state.photoRates = action.payload
    })

    builder.addCase(getBlockList.fulfilled, (state, action) => {
      state.blockedUsersList = action.payload.blockList
    })
  },
})

export const getUserMultimediaInfo = (state: RootState) => state.profileInfo.multimediaList;
export const getBlockedUsersList = (state: RootState) => state.profileInfo.blockedUsersList;
export const getUserCommentsInfo = (state: RootState) => state.profileInfo.commentsList;
export const getUserSubscribers = (state: RootState) => state.profileInfo.subscribers;
export const getUserSubscribes = (state: RootState) => state.profileInfo.subscribes;
export const getPhotoRatesInfo = (state: RootState) => state.profileInfo.photoRates;
export const getUserGuestsInfo = (state: RootState) => state.profileInfo.guests;

export default profileInfo.reducer