// const SERVER_URL = 'https://love-game-api.annaniks.com/';
// const SOCKET_URL = 'wss://love-game-api.annaniks.com:5555';

// const SERVER_URL = 'https://api.truthdare.online:7777';
// const SOCKET_URL = 'wss://api.truthdare.online:7777';

const SERVER_URL = 'https://api.truthdare.online';
const SOCKET_URL = 'wss://api.truthdare.online:5555';
const AUDIO_SERVER_URL = 'https://music.truthdare.online';

const BUNNY_BASE_URL = 'https://storage.bunnycdn.com'
const BUNNY_SDN = 'https://truth-dare.b-cdn.net'
const STORAGE_ZONE_NAME = 'truth-dare-music'

// const SERVER_URL = 'http://192.168.100.45:5555';
// const SOCKET_URL = 'ws://192.168.100.45:5555';

export default {
  SERVER_URL,
  SOCKET_URL,
  AUDIO_SERVER_URL,
  BUNNY_BASE_URL,
  STORAGE_ZONE_NAME,
  BUNNY_SDN,
};