import {
  configureStore, ThunkAction, Action,
} from '@reduxjs/toolkit';
import { enableMapSet } from 'immer';
import notificationsSlice from './slices/notificationsSlice';
import profileInfoSlice from './slices/profileInfoSlice';
import gameInfoSlice from './slices/gameInfoSlice';
import messagesSlice from './slices/messagesSlice';
import topListSlice from './slices/topListSlice';
import usersSlice from './slices/usersSlice';
import audioSlice from './slices/audioSlice';
import clansSlice from './slices/clansSlice';
import gameSlice from './slices/gameSlice';
import authSlice from './slices/authSlice';

// const logger = (store: any) => (next: any) => (action: any) => {
//   console.log('dispatching', action);
//   const result = next(action);
//   console.log('next state', store.getState());
//   return result;
// };

enableMapSet();

export const store = configureStore({
  reducer: {
    game: gameSlice,
    auth: authSlice,
    users: usersSlice,
    audio: audioSlice,
    clans: clansSlice,
    topList: topListSlice,
    messages: messagesSlice,
    gameInfo: gameInfoSlice,
    profileInfo: profileInfoSlice,
    notifications: notificationsSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
    // .concat(logger),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
