/* eslint-disable no-useless-escape */
import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import {
  FormControl, FormHelperText, TextField, Button, InputLabel, CircularProgress,
} from '@mui/material';
import AuthFormHead from '../../../../components/Authentication/Layouts/AuthFormHead';
import { loadingStatus } from '../../../../interfaces/MainInterfaces';
import { checkCode } from '../../../../app/slices/authSlice';
import { useAppDispatch } from '../../../../app/hooks';

const styles = {
  textField: {
    backgroundColor: '#fff',
    width: '100%',
    outline: 'none',
    borderRadius: '5px',
  },
  input: {
    outline: 'none',
  },
}

export default function CheckCode({
  handleChangePage,
}: {
  handleChangePage: (page: null | number) => void
}) {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const {
    handleSubmit, control, formState: { errors },
  } = useForm({
    defaultValues: {
      code: '',
    },
  });

  const [loading, setLoading] = useState<loadingStatus>('loaded')

  const onSubmit = (data: any) => {
    const email = sessionStorage.getItem('reset_pass_email')
    if (email && data.code) {
      setLoading('loading')

      dispatch(checkCode({
        code: +data.code,
        email,
      })).then(response => {
        if (response?.payload?.status < 300) {
          handleChangePage(2)
        } else {
          setLoading('loaded')
        }
      })
    }
    handleChangePage(2)
  };

  return (
    <div className='auth-form'>
      <AuthFormHead
        title='Check your email'
        text='we’ve sent you a verification code'
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputLabel
          className='auth-form-label'
          htmlFor='component-simple'
        >
          Insert the verification code
        </InputLabel>
        <FormControl variant='outlined' className='auth-form-body-input'>
          <Controller
            control={control}
            name='code'
            rules={{
              required: {
                value: true,
                message: t('errors.required'),
              },
            }}
            render={({
              field: {
                onChange, value,
              },
            }) => (
              <TextField
                style={styles.textField}
                inputProps={styles.input}
                id='reset-pass-code'
                placeholder='Code'
                variant='outlined'
                error={!!errors.code}
                value={value}
                onChange={onChange}
              />
            )}
          />
          {!!errors.code && (
            <FormHelperText error id='accountId-error'>
              {errors.code.message}
            </FormHelperText>
          )}
        </FormControl>
        <Button
          variant='contained'
          type='submit'
          style={{
            backgroundColor: '#2D836D',
            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
            borderRadius: '5px',
            width: '100%',
            padding: '15px 0',
            fontWeight: '800',
            color: 'F3F4F8',
          }}
        >
          {loading === 'loading' ? <CircularProgress /> : 'Change password'}
        </Button>
      </form>
    </div>
  )
}