import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import messagesSocket from '../../../utils/socket/messagesSocket';
import { getUserInfo } from '../../../app/slices/usersSlice';
import { changePrivateMessagesData } from '../../../app/slices/messagesSlice';

export default function MessagesSocket() {
  const dispatch = useAppDispatch()

  const userInfo = useAppSelector(getUserInfo);

  const handleSetMessages = useCallback((data) => {
    dispatch(changePrivateMessagesData({
      list: data,
    }))
  }, [])

  // useEffect(() => {
  //   if (userInfo?.user?.id) {
  //     messagesSocket.emit('get-conversations', {
  //       take: 1000,
  //       skip: 0,
  //       userId: userInfo.user.id,
  //     })
  //   }
  // }, [userInfo])

  useEffect(() => {
    messagesSocket.on('get-conversations', handleSetMessages)

    return () => {
      messagesSocket.off('get-conversations', handleSetMessages)
    }
  }, []);
}