import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
  dailyGift,
  gameMemberInfo, userInfo, userRateType,
} from '../../interfaces/GameTableInterfaces';
import { loadingStatus } from '../../interfaces/MainInterfaces';
import imageUpload from '../../utils/upload/cloudinaryUpload';
import getAccessToken from '../../utils/users/getAccesToken';
import AuthAPI from '../../services/AuthAPI';
import API from '../../services/API';
import { RootState } from '../store';

interface initState {
  user: {
    token: string,
    user: userInfo | null
  } | null,
  status: string,
  profileUser: {
    user: userInfo,
    isFollow: boolean,
  } | null,
  usersList: {
    info: {
      userList: userInfo[],
      count: number
    },
    status: loadingStatus
  },
  loginStatus: string,
  registrationStatus: string,
  profileUserStatus: string,
  roomInfo: {
    members: gameMemberInfo[]
  },
  moneyInfo: {
    moneyInfo: {
      goldCount: number,
      dimondCount: number,
      point: number
      crazyPoint: number
    }
  },
  boostLoading: loadingStatus,
  userRates: userRateType[]
  dailyGift: dailyGift | null
}

interface getMeData {
  socialId: number | undefined,
  socialType: string
}

const initialState: initState = {
  user: {
    token: '',
    user: null,
  },
  profileUser: null,
  status: 'loading',
  loginStatus: 'loading',
  registrationStatus: 'loaded',
  profileUserStatus: 'loading',
  usersList: {
    info: {
      count: 0,
      userList: [],
    },
    status: 'loading',
  },
  roomInfo: {
    members: [],
  },
  moneyInfo: {
    moneyInfo: {
      goldCount: 0,
      dimondCount: 0,
      point: 0,
      crazyPoint: 0,
    },
  },
  boostLoading: 'loaded',
  userRates: [],
  dailyGift: null,
};

export const authLogin = createAsyncThunk(
  'users/authLogin',
  async (data: {
    data: {
      email: string,
      password: string
    },
    cb: any
  }) => {
    const response = await API.post('/auth/login/', {
      email: data.data.email,
      password: data.data.password,
    })

    const { token, user } = response.data

    localStorage.setItem('access_token', token)
    localStorage.setItem('userId', user.id)

    if (data.cb) {
      data.cb(token, user)
    }

    return response.data
  },
)

export const authRegistration = createAsyncThunk(
  'users/authRegistration',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API.post('/auth/reg', data)

      localStorage.setItem('access_token', response.data.token)

      data.cb()

      return response.data
    } catch (err: any) {
      return rejectWithValue(err.response.data)
    }
  },
)

export const getMe = createAsyncThunk(
  'users/getMe',
  async (data: getMeData) => {
    const response = await API.post('/user/login/', {
      socialId: data.socialId,
      socialType: data.socialType,
    });

    const tokenName = data.socialType ? `access_token_${data.socialType}` : 'access_token'

    localStorage.setItem('userId', response.data.user.id)
    localStorage.setItem(tokenName, response.data.token)

    return response.data;
  },
);

export const getMyUser = createAsyncThunk(
  'users/getMyUser',
  async () => {
    const token = await getAccessToken() || ''

    const response = await API.get('/user/me', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return {
      token,
      ...response.data,
    }
  },
)

export const getUser = createAsyncThunk(
  'users/me',
  async () => {
    const token = await getAccessToken() || ''

    const response = await API.get('/user/me', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return {
      token,
      ...response.data,
    }
  },
)

export const changeUserInfo = createAsyncThunk(
  'users/changeUserInfo',
  async (data: any) => {
    const response = await API.put(`/user/${data.id}`, data);

    return response.data;
  },
);

export const updateUserInfo = createAsyncThunk(
  'users/updateUserInfo',
  async ({
    data, file, id, cb,
  }: { data: any, file?: any, id: number, cb?: () => void }) => {
    let image: any = data.avatar || '';
    if (file) {
      image = await imageUpload(file)
      image = image.data.public_id
    }

    const response = await API.put(`/user/${id}`, {
      ...data,
      avatar: image || '',
    });

    if (cb) {
      cb()
    }

    return response.data;
  },
);

export const updateUserInterfaceLanguage = createAsyncThunk(
  'users/updateUserInterfaceLanguage',
  async ({ interfaceLang, id }: any) => {
    const response = await API.put(`/user/${id}`, {
      interfaceLang,
    });

    return response.data;
  },
);

export const getUserById = createAsyncThunk(
  'users/getUserById',
  async (id: number) => {
    const response = await AuthAPI.get(`/user/getUser/${id}`)

    return response.data
  },
)

export const getRoomMembers = createAsyncThunk(
  'game/getRoomMembers',
  async (data: {
    membersIds: number[],
    members: {
      top: { userId: number }[],
      bottom: { userId: number }[]
    }
  }) => {
    const response: {
      data: {
        usersList: userInfo[]
      }
    } = await API.post('/user/byIds', {
      userIds: data.membersIds,
    });

    const top = data.members.top.map((item) => Object.assign(response.data.usersList.find((user) => user.id === item.userId) || {}, {
      side: 'top',
    }));

    const bottom = data.members.bottom.map((item) => Object.assign(response.data.usersList.find((user) => user.id === item.userId) || {}, {
      side: 'bottom',
    }));

    return {
      data,
      users: [...top, ...bottom],
    };
  },
);

export const activatePremiumStatus = createAsyncThunk(
  '/users/activePremiumStatus',
  async (data: any) => {
    await AuthAPI.post('/user/premium')

    data.cb()
  },
)

export const activateLuckyStatus = createAsyncThunk(
  '/users/activeLuckyStatus',
  async (data: any) => {
    await AuthAPI.post('/user/buy/lucky-status')

    data.cb()
  },
)

export const sendReport = createAsyncThunk(
  '/users/sendReport',
  async (data: {
    desc: string,
    receiverId: number,
    cb: any
  }) => {
    await AuthAPI.post('/report', {
      desc: data.desc,
      receiverId: data.receiverId,
    })

    data.cb()
  },
)

export const getUserMoney = createAsyncThunk(
  '/users/getUserMoney',
  async () => {
    const response = await AuthAPI.get('/user/money-point/my');

    return response.data
  },
)

export const getUsers = createAsyncThunk(
  '/users/getUsers',
  async (search: string) => {
    const response = await AuthAPI.get(`/user/all${search}`)

    return response.data
  },
)

export const acceptInvite = createAsyncThunk(
  '/users/acceptInvite',
  async (id: number) => {
    await AuthAPI.post('/invite/vk', {
      inviterId: id,
    })
  },
)

export const getBoost = createAsyncThunk(
  '/users/getBoost',
  async (data: {
    days: number,
    boosterCount: number
  }) => {
    const response = await AuthAPI.post('/user/point/boost', data)

    return response.data
  },
)

export const getUserRates = createAsyncThunk(
  '/users/getUserRates',
  async (id: number) => {
    const response = await AuthAPI.get(`/RateType/user/${id}`)

    return response.data
  },
)

export const getCashback = createAsyncThunk(
  '/users/getCashback',
  async (cashback: any) => {
    const response = await AuthAPI.post('/user/cashing-out', {
      count: cashback,
    })

    return response.data
  },
)

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setProfileUserLoading: (state, action) => {
      state.profileUserStatus = action.payload
    },
    changeRoomMembers: (state, action) => {
      state.roomInfo.members = action.payload
    },
    changeProfileLoadingStatus: (state, action) => {
      state.profileUserStatus = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMe.pending, (state) => {
      state.loginStatus = 'loading'
    });
    builder.addCase(getMe.fulfilled, (state, action) => {
      state.user = action.payload;

      state.loginStatus = 'loaded'

      state.dailyGift = action.payload.dailyGift

      const { user } = action.payload;

      if (user.firstName !== null) {
        state.status = 'loaded';
      }
    });
    builder.addCase(getMe.rejected, (state) => {
      state.loginStatus = 'loaded'
    });
    builder.addCase(changeUserInfo.fulfilled, (state, action) => {
      state.user = {
        token: state.user?.token || '',
        user: action.payload,
      };
      state.profileUserStatus = 'loaded'
    });

    builder.addCase(getUser.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.user = action.payload;

      state.dailyGift = action.payload.dailyGift

      state.status = 'loaded';
    });

    builder.addCase(getMyUser.pending, (state, action) => {
      state.boostLoading = 'loading';
    });
    builder.addCase(getMyUser.fulfilled, (state, action) => {
      state.user = action.payload;

      state.dailyGift = action.payload.dailyGift

      state.boostLoading = 'loaded';
      state.status = 'loaded';
    });

    builder.addCase(updateUserInfo.pending, (state) => {
      state.status = 'loading';
      state.registrationStatus = 'loading'
    });

    builder.addCase(updateUserInfo.fulfilled, (state, action) => {
      state.user = { user: action.payload, token: state.user?.token || '' };
      state.status = 'loaded';
      state.registrationStatus = 'loaded'
    });

    builder.addCase(updateUserInfo.rejected, (state) => {
      state.status = 'loaded';
      state.registrationStatus = 'loaded'
    });

    builder.addCase(authLogin.fulfilled, (state, action) => {
      state.status = 'loaded';

      state.user = action.payload

      state.dailyGift = action.payload.dailyGift
    })

    builder.addCase(authLogin.rejected, (state) => {
      state.status = 'loading';

      state.loginStatus = 'failed'
    })

    builder.addCase(authRegistration.pending, (state) => {
      state.registrationStatus = 'loading'
    })
    builder.addCase(authRegistration.fulfilled, (state, action: PayloadAction<any>) => {
      localStorage.setItem('userId', action.payload.user.id)

      state.registrationStatus = 'loaded'
    })
    builder.addCase(authRegistration.rejected, (state, action: PayloadAction<any>) => {
      toast.error(action.payload?.message)

      state.registrationStatus = 'loaded'
    })

    builder.addCase(getUserById.pending, (state) => {
      state.profileUserStatus = 'loading';
    })

    builder.addCase(getUserById.fulfilled, (state, action: PayloadAction<any>) => {
      state.profileUser = action.payload;
      state.profileUserStatus = 'loaded';
    })

    builder.addCase(getRoomMembers.fulfilled, (state, action: PayloadAction<any>) => {
      state.roomInfo.members = action.payload.users;
    });


    builder.addCase(getUserMoney.fulfilled, (state, action) => {
      state.moneyInfo = action.payload
    })

    builder.addCase(getUsers.pending, (state) => {
      state.usersList.status = 'loading'
    })
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.usersList.status = 'loaded'
      state.usersList.info = action.payload
    })

    builder.addCase(getBoost.pending, (state) => {
      state.boostLoading = 'loading'
    })
    builder.addCase(getBoost.fulfilled, (state) => {
      state.boostLoading = 'loaded'
    })

    builder.addCase(getUserRates.fulfilled, (state, action) => {
      state.userRates = action.payload
    })
  },
});

export const {
  setProfileUserLoading,
  changeRoomMembers,
  changeProfileLoadingStatus,
} = usersSlice.actions;

export const getUserInfo = (state: RootState) => state.users.user;
export const getUserStatus = (state: RootState) => state.users.status;
export const getLoginStatus = (state: RootState) => state.users.loginStatus;
export const getRegistrationStatus = (state: RootState) => state.users.registrationStatus;
export const getProfileUser = (state: RootState) => state.users.profileUser;
export const getProfileUserStatus = (state: RootState) => state.users.profileUserStatus;
export const getRoomMembersData = (state: RootState) => state.users.roomInfo?.members;

export const getUserMoneyInfo = (state: RootState) => state.users.moneyInfo;
export const getUsersList = (state: RootState) => state.users.usersList;
export const getBoostLoading = (state: RootState) => state.users.boostLoading;
export const getUserRatesInfo = (state: RootState) => state.users.userRates;
export const getDailyGiftInfo = (state: RootState) => state.users.dailyGift;

export default usersSlice.reducer;