export default function getLocale(lang) {
  switch (lang?.toLowerCase()) {
    case 'ru':
      return 'ru'
    case 'en':
      return 'en'
    case 'cn':
      return 'zh-cn'
    case 'fr':
      return 'fr'
    case 'kr':
      return 'ko'
    case 'jp':
      return 'ja'
    case 'es':
      return 'es'

    default:
      return 'en'
  }
}