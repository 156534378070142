/* eslint-disable no-constructor-return */
import { io } from 'socket.io-client';
import keys from '../../services/keys';
import { getDeviceId } from '../users/checkUserInfo';

class Socket {
  private socket: any = io(keys.SOCKET_URL, {
    autoConnect: false,
  });

  async connectWithAuthToken(token: string) {
    this.socket.io.opts.extraHeaders = {}
    this.socket.io.opts.extraHeaders.Authorization = `Token ${token}`
    this.socket.io.opts.extraHeaders.Device = getDeviceId()

    if (!this.socket.connected) {
      await this.socket?.disconnect();

      this.socket.connect();

      this.socket.on('connect', () => {
        console.log('socket is connected');
      });

      this.socket.on('disconnected', () => {
        console.log('socket is disconnected');
      });
    }
  }

  get socketInfo() {
    return this.socket;
  }

  disconnect() {
    this.socket?.emit('leave');
    this.socket?.disconnect();
  }

  emit(event: string, data: any) {
    this.socket?.emit(event, data);
  }

  on(event: string, callBack: (data: any) => void) {
    if (this.socket) {
      this.socket?.off(event, callBack)
      this.socket?.on(event, callBack);
    }
  }

  off(event: string, callback: (data: any) => void) {
    this.socket?.off(event, callback)
  }

  joinRoom(roomId?: number) {
    this.socket?.emit('join', roomId);
  }
}

export default new Socket();