import Language from '../../../Navbar/NavbarHead/Language'
import '../../style.scss'

interface props {
  text: string
}

export default function AuthHeader({
  text,
}: props) {
  return (
    <div className='auth-header'>
      <span>{text}</span>
      <Language />
    </div>
  )
}