import dayjs from 'dayjs';
import notificationsSocket from './notificationsSocket';
import messagesSocket from './messagesSocket';
import socket from './socket';

export default function connectToSocket(token, user, connect) {
  if (dayjs().diff(dayjs(user?.birthday), 'year') >= 18) {
    if (connect) {
      socket.connectWithAuthToken(token);
      messagesSocket.connectWithAuthToken(token)
      notificationsSocket.connectWithAuthToken(token)
    } else {
      setTimeout(() => {
        window.dispatchEvent(new CustomEvent('first_login'))
      }, 2000);
    }
  }
}

export function disconnectFromSocket() {
  socket.disconnect();
  messagesSocket.disconnect()
  notificationsSocket.disconnect()
}