import {
  lazy,
  useEffect,
  useMemo, useState,
} from 'react';
import {
  Route, Routes,
} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import FBUpdateInfoBanner from './components/Banners/FBUpdateInfoBanner';
import getCurrentEnv, { getSocialEnv } from './utils/variables';
import AuthenticationHooks from './hooks/AuthenticationHooks';
import { getUserInfo } from './app/slices/usersSlice';
import { MyGlobalContext } from './utils/context';
import SocketHooks from './hooks/SocketHooks';
import { useAppSelector } from './app/hooks';

import 'react-toastify/dist/ReactToastify.css';

import 'dayjs/locale/en'
import 'dayjs/locale/zh-cn'
import 'dayjs/locale/ko'
import 'dayjs/locale/fr'
import 'dayjs/locale/ru'
import 'dayjs/locale/es'
import 'dayjs/locale/ja'
import ResetPassword from './features/Authentication/ui/ResetPassword';

const Registration = lazy(() => import('./features/Authentication/ui/Registration'))
const Login = lazy(() => import('./features/Authentication/ui/Login'))
const CreateUser = lazy(() => import('./components/CreateUser'))
const Main = lazy(() => import('./pages/Main'))

export default function App() {
  const socketHooks = SocketHooks();
  const authenticationHooks = AuthenticationHooks();

  const { i18n } = useTranslation()

  const initialColor = useMemo(() => (localStorage.getItem('theme') ? localStorage.getItem('theme') : 'green'), [])
  const userInfo = useAppSelector(getUserInfo);

  const [theme, setTheme] = useState<any>(initialColor);

  const initialValue = useMemo(() => ({ theme, setTheme }), [theme]);

  useEffect(() => {
    if (getCurrentEnv() === 'vk' || getCurrentEnv() === 'ok' || getCurrentEnv() === 'ml') {
      i18n.changeLanguage('ru')
    }

    if (window.location.host !== 'localhost:3000') {
      navigator.mediaDevices
        ?.getUserMedia({ video: true, audio: true })
        .catch((err) => {
          toast.error('Please enable camera and microphone permissions to access video games.')
        });
    }
  }, [])

  if (userInfo?.user && !userInfo.user.firstName) {
    if (!getSocialEnv()) {
      return <CreateUser />
    }
  }

  return (
    <>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ zIndex: 100000000 }}
      />
      <MyGlobalContext.Provider value={initialValue}>
        {/* eslint-disable-next-line no-nested-ternary */}
        <div className={theme === 'green' ? 'green' : (theme === 'purple' ? 'purple' : (theme === 'yellow' ? 'yellow' : 'black'))}>
          <Routes>
            <Route
              path='/'
              element={
                <Main />
              }
            />
            <Route
              path='/login'
              element={
                <Login />
              }
            />
            <Route
              path='/registration'
              element={
                <Registration />
              }
            />
            <Route
              path='/reset-password'
              element={
                <ResetPassword />
              }
            />
          </Routes>
          <FBUpdateInfoBanner />
        </div>
      </MyGlobalContext.Provider>
    </>
  )
}