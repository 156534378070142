import axios from 'axios';
import keys from './keys';
import getAccessToken from '../utils/users/getAccesToken';
import { getDeviceId } from '../utils/users/checkUserInfo';

const AuthAPI = axios.create({
  baseURL: keys.SERVER_URL,
  responseType: 'json',
  timeout: 10000,
});

AuthAPI.interceptors.request.use(
  req => {
    req.headers.Authorization = `Bearer ${getAccessToken()}`

    req.headers.device = getDeviceId()
    return req
  },
)

export default AuthAPI;