import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import AuthHeader from '../../../../components/Authentication/Layouts/Header';
import SendCodeToEmail from './SendCodeToEmail';
import CreatePassword from './ResetPassword';
import CheckCode from './CheckCode';

export default function ResetPassword() {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const [activePage, setActivePage] = useState<number | null>(0)

  const handleChangePage = useCallback((page: number | null) => {
    if (page === -1) {
      navigate('/login')
    } else {
      setActivePage(page)
    }
  }, [])

  return (
    <div className='auth'>
      <AuthHeader text='Recover password' />
      <div className='auth-go-back' onClick={() => handleChangePage((activePage || 0) - 1)}>
        <KeyboardArrowLeftIcon />
      </div>
      {activePage === 0 && (
        <div className='animate__animated animate__backInRight'>
          <SendCodeToEmail handleChangePage={handleChangePage} />
        </div>
      )}
      {activePage === 1 && (
        <div className='animate__animated animate__backInRight'>
          <CheckCode handleChangePage={handleChangePage} />
        </div>
      )}
      {activePage === 2 && (
        <div className='animate__animated animate__backInRight'>
          <CreatePassword handleChangePage={handleChangePage} />
        </div>
      )}
      <div className='auth-footer'>
        <span className='auth-form-header-text'>
          {t('registration.alreadyHaveAccount')}
          {' '}
          <b><Link to='/login'>{t('login.login')}</Link></b>
        </span>
      </div>
    </div>
  )
}