import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import AuthAPI from '../../services/AuthAPI'
import { RootState } from '../store'
import { notificationItem } from '../../interfaces/NotificationsInterfaces'

// /notification/all / unreaded ? skip = 0 & take=10

interface initState {
  notificationsList: notificationItem[]
}

const initialState: initState = {
  notificationsList: [],
}

export const getNotifications = createAsyncThunk(
  'notifications/getNotifications',
  async () => {
    const response = await AuthAPI.get('/notification/all/unreaded?skip=0&take=1000')

    return response.data
  },
)

export const sendViewedNotifications = createAsyncThunk(
  'notifications/sendViewedNotifications',
  async (data: {
    ids: number[],
    cb: any
  }) => {
    const response = await AuthAPI.put('/notification/set-readed', {
      ids: data.ids,
    })

    data.cb()

    return response.data
  },
)

const notifications = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNotifications.fulfilled, (state, action: PayloadAction<{
      notificationList: notificationItem[]
    }>) => {
      state.notificationsList = action.payload.notificationList
    })
  },
})

export const getNotificationsList = (state: RootState) => state.notifications.notificationsList

export default notifications.reducer