import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ConversationItem } from '../../interfaces/MessagesInterfaces'
import AuthAPI from '../../services/AuthAPI'
import { RootState } from '../store'
// /notification/all / unreaded ? skip = 0 & take=10

interface initState {
  privateMessages: {
    list: ConversationItem[] | null,
    open: boolean,
    chatId: number | null
  },
}

const initialState: initState = {
  privateMessages: {
    list: null,
    open: false,
    chatId: null,
  },
}

export const setMessagesReaded = createAsyncThunk(
  'messages/setMessagesReaded',
  async (id: number) => {
    const response = await AuthAPI.post('/message/set/readed/all', {
      fromId: id,
    })

    return response.data
  },
)

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    openPrivateMessagesModal: (state) => {
      state.privateMessages.open = true
    },
    closePrivateMessagesModal: (state) => {
      state.privateMessages.open = false
    },
    changePrivateMessagesData: (state, action) => {
      state.privateMessages = {
        ...state.privateMessages,
        ...action.payload,
      }
    },

  },
  extraReducers: (builder) => {

  },
})

export const {
  openPrivateMessagesModal,
  closePrivateMessagesModal,
  changePrivateMessagesData,
} = messagesSlice.actions

export const getPrivateMessagesList = (state: RootState) => state.messages.privateMessages.list
export const getPrivateMessagesInfo = (state: RootState) => state.messages.privateMessages

export default messagesSlice.reducer