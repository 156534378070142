import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import AuthAPI from '../../services/AuthAPI'

interface initState {
}

const initialState: initState = {
}

export const sendCheckCode = createAsyncThunk(
  'auth/sendCheckCode',
  async (data: {
    email: string,
    interfaceLang: string
  }) => {
    const response = await AuthAPI.post('/auth/password/message', data)

    return response.data;
  },
)

export const checkCode = createAsyncThunk(
  'auth/checkCode',
  async (data: {
    email: string,
    code: number
  }) => {
    const response = await AuthAPI.post('/auth/password/message', data)

    return response.data;
  },
)

export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async (data: {
    email: string,
    password: string
  }) => {
    const response = await AuthAPI.post('/auth/password/change/by-code', data)

    return response.data;
  },
)

export const socialSave = createAsyncThunk(
  'auth/socialSave',
  async (data: {
    socialId: string,
    socialType: string
  }, { rejectWithValue }) => {
    try {
      const response = await AuthAPI.post('/auth/social/save', data)

      return response.data;
    } catch (error: any) {
      if (!error?.response) {
        throw error
      }

      return rejectWithValue(error.response.data)
    }
  },
)

export const changeBySocial = createAsyncThunk(
  'auth/changeBySocial',
  async (data: {
    password: string,
    socialId: string,
    socialType: string,
    changeBySocial: boolean,
  }, { rejectWithValue }) => {
    try {
      const response = await AuthAPI.post('/auth/social/change/profile', data)

      return response.data;
    } catch (error: any) {
      if (!error?.response) {
        throw error
      }



      return rejectWithValue(error.response.data)
    }
  },
)

const audio = createSlice({
  name: 'audio',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(changeBySocial.rejected, (state, action: any) => {
      toast.error(action.payload.message)
    })
  },
})

export default audio.reducer