import {
  Suspense,
} from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter as Router } from 'react-router-dom';
// import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import Loading from './components/Loading';
import { store } from './app/store';
import App from './App';
import 'animate.css';
import './i18n'
import './App.scss';

const root = document.getElementById('root') as HTMLElement;

createRoot(root).render(
  <Provider store={store}>
    <Suspense fallback={<Loading />}>
      <Router>
        <App />
      </Router>
    </Suspense>
  </Provider>,
);

reportWebVitals();